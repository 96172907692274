<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Daily Attendance'"
          ></span>
          <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            to="/user-attendances/create"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData"
                    @change="page = 1"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="employee"
                    v-model="user_id"
                    :items="employees"
                    label="Employee"
                    @input="getData"
                    prepend-icon="mdi-account"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="2">
                  <v-autocomplete
                    ref="supervisor"
                    v-model="supervisor_id"
                    :items="supervisors"
                    label="Supervisor"
                    @input="getData"
                    prepend-icon="mdi-account"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="2">
                  <v-autocomplete
                    ref="role"
                    v-model="session_type_id"
                    :items="sessionTypes"
                    label="Session Type"
                    @input="getData"
                    prepend-icon="mdi-account"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="2">
                  <v-autocomplete
                    ref="status"
                    v-model="active"
                    :items="statuses"
                    label="Employee Status"
                    @input="getData"
                    prepend-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-menu
                    v-model="month_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.month_year"
                        label="Year - Month"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="filter.month_year = null"
                        :error-messages="errors.month"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.month_year"
                      type="month"
                      @change="(month_menu = false), getData()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="userAttendances.length != 0"
              ><v-spacer></v-spacer
              ><v-btn
                class="primary mx-4"
                v-if="ExportUserAttendances.length == 0"
                @click="generateExcel()"
                ><span v-if="isExcelloading"> Generating Excel... </span>
                <span v-else>Generate Excel</span></v-btn
              >
              <v-btn
                target="_blank"
                :href="`${mediaUrl}store-vyu/exports/UsersExport.xlsx`"
                v-else
                class="success mx-4"
              >
                <!-- <download-excel
                  :title="title"
                  :data="ExportUserAttendances"
                  :fields="excel_fields"
                  worksheet="UserAttendances"
                  name="UserAttendances.xls"
                  >Download Excel
                </download-excel> -->
              </v-btn></v-card-title
            >
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Employee Name</th>
                    <th class="text-left">Date</th>
                    <th class="text-left">Login Time</th>
                    <th class="text-left">Logout Time</th>
                    <th class="text-left">Session Type</th>
                    <th class="text-left">Supervisor</th>
                    <th class="text-right" v-if="roleName == 'SUPER ADMIN'">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody v-if="userAttendances.length != 0">
                  <tr v-for="(user, i) in userAttendances" :key="`user${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="user.active == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>User is Active</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="user.active == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="red"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>User is InActive</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>{{ user.user.name || "" }}</td>
                    <td>{{ user.date || "" }}</td>
                    <td>{{ user.login_time | hhmm }}</td>
                    <td>{{ user.logout_time | hhmm }}</td>
                    <td>{{ user.session_type.code || "" }}</td>
                    <td>{{ user.user.supervisor.name || "" }}</td>

                    <td class="text-right" v-if="roleName == 'SUPER ADMIN'">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/user-attendances/${user.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="8" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "userAttendancesPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      supervisor_id: "",
      user_id: "",
      session_type_id: "",
      active: true,
      month_menu: false,
      attendance_type: "Normal",
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      statuses: [
        {
          id: 0,
          text: "Select Employee Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: true },
        { id: "INACTIVE", text: "INACTIVE", value: false },
      ],

      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      sessionTypes: [
        {
          id: 0,
          text: "Select Session Type",
          value: "",
        },
      ],
      isLoading: false,
      isExcelloading: false,
      userAttendances: [],
      ExportUserAttendances: [],

      excel_fields: {
        "Sr No": "sr_no",
        "Employee Name": "employee_name",
        Date: "date",
        "Login Time": "login_time",
        "Logout Time": "logout_time",
        "Session Type": "session_type_id",
        "Supervisor Name": "supervisor_name",
      },
    };
  },
  watch: {
    page: "getData",
    month_menu: "getMonthYear",
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.supervisor_id = this.user.id;
    }
    this.getData();
    this.getMasters();

    console.log(moment("11:29:30", ["HH:mm:ss"]).format("hh:mm A"));
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (employee.supervisor_id == this.user.id) {
            this.employees.push({
              id: employee.id,
              text: employee.name,
              value: employee.id,
            });
          }
        } else {
          this.employees.push({
            id: employee.id,
            text: employee.name,
            value: employee.id,
          });
        }
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (supervisor.id == this.user.id) {
            this.supervisors.push({
              id: supervisor.id,
              text: supervisor.name,
              value: supervisor.id,
            });
          }
        } else {
          this.supervisors.push({
            id: supervisor.id,
            text: supervisor.name,
            value: supervisor.id,
          });
        }
      });
      // Session Types
      masters.sessionTypes.forEach((sessionType) => {
        this.sessionTypes.push({
          id: sessionType.id,
          text: sessionType.code,
          value: sessionType.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.active = this.active ? true : false;
      this.supervisorId = this.supervisorId ? this.supervisorId : "";
      this.user_id = this.user_id ? this.user_id : "";
      this.attendance_type = this.attendance_type ? this.attendance_type : "";
      this.session_type_id = this.session_type_id ? this.session_type_id : "";
      let userAttendances = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&sessionTypeId=" +
        this.session_type_id +
        "&attendanceType=" +
        this.attendance_type +
        "&employee_status=" +
        this.active +
        "&userId=" +
        this.user_id +
        "&supervisorId=" +
        this.supervisor_id;
      userAttendances = await axios.get(`user_attendances?${query}`);
      this.userAttendances = userAttendances.data.data;
      console.log(this.userAttendances);
      this.count = userAttendances.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
    //Generate Excel
    async generateExcel() {
      let query =
        "&search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&sessionTypeId=" +
        this.session_type_id +
        "&attendanceType=" +
        this.attendance_type +
        "&employee_status=" +
        this.active +
        "&userId=" +
        this.user_id +
        "&supervisorId=" +
        this.supervisor_id;
      let response = await axios.get(`user_Attendances_Exports?${query}`);
      console.log(response.data.data);
      if (response) {
        window.open(`${response.data.data}`, "_blank");
      }
    },

    getMonthYear() {
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];

        this.totalDaysInMonth = moment(
          this.filter.month_year,
          "YYYY-M"
        ).daysInMonth();
        console.log(this.totalDaysInMonth);
      }
    },
  },
};
</script>
