<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Expenses'"></span>
        </v-col>
      </v-row>

      <!-- Filters -->
      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData"
                    @change="page = 1"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="employee"
                    v-model="filter.user_id"
                    :items="employees"
                    label="Employee"
                    @input="getData"
                    prepend-icon="mdi-account"
                    :error-messages="errors.user_id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3">
                  <v-menu
                    v-model="month_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.month_year"
                        label="Year - Month"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="filter.month_year = null"
                        :error-messages="errors.month"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.month_year"
                      type="month"
                      @change="(month_menu = false), getData()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- / Filters -->
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="expenses.length != 0"
              ><v-spacer></v-spacer>
              <v-col md="2">
                <v-text-field
                  v-model="total_expense_amount"
                  outlined
                  readonly
                  disabled
                  label="Total Expense amount"
                  messages="Total Expense amount"
                  prepend-inner-icon="mdi-cash"
                ></v-text-field>
              </v-col>
              <!-- Approve All Selected Expense -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    x-large
                    v-on="on"
                    @click="UpdateApprovalStatus('APPROVE')"
                    icon
                    :loading="isApproving"
                    ><v-icon>mdi-cash-check</v-icon></v-btn
                  >
                </template>
                <span>Approve All</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    v-bind="attrs"
                    x-large
                    v-on="on"
                    @click="UpdateApprovalStatus('DENY')"
                    icon
                    :loading="isDenying"
                    ><v-icon>mdi-cash-remove</v-icon></v-btn
                  >
                </template>
                <span>Denied All</span>
              </v-tooltip>
              <!--End of Approve All Selected Expense -->
              <!-- Download Excel -->

              <v-btn
                class="primary mx-4"
                v-if="ExportExpences.length == 0"
                @click="addToExcelData()"
                ><span v-if="isExcelloading"> Generating Excel... </span>
                <span v-else>Generate Excel</span></v-btn
              >
              <v-btn v-else class="success mx-4"
                ><download-excel
                  :title="title"
                  :data="ExportExpences"
                  :fields="excel_fields"
                  worksheet="Expense-Report"
                  name="Expense-Report.xls"
                  >Download Excel
                </download-excel></v-btn
              ></v-card-title
            >
            <!--end of Download Excel -->
            <!-- Table -->
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">
                      <v-checkbox
                        @click="selectAll()"
                        label="Select All"
                        v-model="is_select_all"
                      ></v-checkbox>
                    </th>
                    <th class="text-left" style="min-width: 150px">
                      Employee Name
                    </th>
                    <th class="text-left">Employee Code</th>
                    <th class="text-left">Date</th>
                    <th class="text-left">Town/City</th>
                    <th class="text-left" v-if="companyId == 9">Allowance Type</th>
                    <th class="text-left" v-if="companyId == 9">Allowance</th>
                    <th class="text-left">Visited</th>
                    <th class="text-left">Expense Type</th>
                    <th class="text-left">Travel Mode</th>
                    <th class="text-left">From</th>
                    <th class="text-left">To</th>
                    <th class="text-left">Description</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Bill</th>
                    <th class="text-left" style="min-width: 150px">
                      Supervisor
                    </th>
                  </tr>
                </thead>
                <tbody v-if="expenses.length != 0">
                  <tr v-for="(expense, i) in expenses" :key="`expense${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="expense.is_approved == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Visited</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="expense.is_approved == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="red"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Not Visited</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>
                      <v-checkbox v-model="expense.is_selected"></v-checkbox>
                    </td>
                    <td>{{ expense.user.name || "" }}</td>
                    <td>{{ expense.user.employee_code || "" }}</td>
                    <td>{{ expense.pjp.date || "" }}</td>
                    <td>{{ expense.pjp.location || "" }}</td>
                    <td v-if="companyId == 9">{{ expense.pjp.allowance_type?.code || "" }}</td>
                    <td v-if="companyId == 9">100</td>
                    <td>{{ expense.pjp.is_visited == 1 ? "YES" : "NO" }}</td>
                    <td>
                      {{
                        expense.expense_type ? expense.expense_type.code : ""
                      }}
                    </td>
                    <td>
                      {{ expense.travel_mode ? expense.travel_mode.code : "" }}
                    </td>
                    <td>{{ expense.from || "" }}</td>
                    <td>{{ expense.to || "" }}</td>
                    <td>{{ expense.description || "" }}</td>
                    <td>{{ expense.amount || "" }}</td>
                    <td>
                      <a
                        v-if="expense.image_path"
                        target="_blank"
                        :href="`${mediaUrl}store-vyu/${expense.image_path}`"
                        >View Bill</a
                      >
                    </td>
                    <td>{{ expense.supervisor.name || "" }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!--End of Table -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "expensesPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
        expense_id: "",
       

      },
      page: 1,
     
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      supervisor_id: "",
      month_menu: false,
      isApproving: false,
      isDenying: false,
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
companyId: "",
      isLoading: false,
      is_select_all: false,
      isExcelloading: false,
      expenses: [],
      ExportExpences: [],
      All_expenses: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        "Employee Code": "employee_code",
        Date: "date",
        "Town/City": "location",
        ...(this.companyId == 9 ? {"Allowance Type": "allowance_type", Allowance: "allowance"}: {}),
       
        Visited: "is_visited",
        "Expense Type": "expense_type",
        "Travel Mode": "travel_mode",
        From: "from",
        To: "to",
        Description: "description",
        Amount: "amount",
        "Is Approved": "status",
        Supervisor: "supervisor",
        Bill: "bill",
      },
    };
  },
  watch: {
    page: "getData",
    month_menu: "getMonthYear",
  },
  mounted() {
    this.getMasters();
    this.companyId = this.user.companies[0].id;
    console.log(this.companyId);
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`pjp_expenses/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.name,
          value: employee.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&user_id=" +
        this.filter.user_id;
      await axios
        .get(`expenses?${query}`)
        .then((expenses) => {
          this.ExportExpences = [];
          this.expenses = expenses.data.data;
          console.log(this.expenses);
          this.total_expense_amount = 0;
          this.expenses.forEach((expense) => {
            expense.is_selected = false;
            this.total_expense_amount += parseInt(expense.amount);
             if(this.companyId==9){
             this.total_expense_amount += 100;
           }
            //  this.total_expense_amount += 100;
          });
          this.count = expenses.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    async addToExcelData() {
      
      var total = 0;
      this.ExportExpences = [];
      this.All_expenses = [];
      this.isExcelLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
        this.title =
          "Expense Report | " + this.filter.month + " " + this.filter.year;
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&user_id=" +
        this.filter.user_id;
      await axios
        .get(`expenses?${query}`)
        .then((response) => {
          this.All_expenses = response.data.data;
          console.log(this.All_expenses);
          let sr_no = 1;
          this.All_expenses.forEach((expense) => {
            console.log(sr_no);
            let excel_expences = {
              sr_no: sr_no++,
              name: expense.user.name ? expense.user.name.toUpperCase() : "",
              employee_code: expense.user.employee_code
                ? expense.user.employee_code.toUpperCase()
                : "",
              date: expense.pjp.date ? expense.pjp.date.toUpperCase() : "",
              location: expense.pjp.location
                ? expense.pjp.location.toUpperCase()
                : "",
              allowance_type: expense.pjp.allowance_type
                ? expense.pjp.allowance_type.code
                : "",
                allowance: this.companyId == 9 ? "100" : "",
              //allowance: "100",
              is_visited: expense.pjp.is_visited ? "YES" : "NO",
              expense_type: expense.expense_type
                ? expense.expense_type.code.toUpperCase()
                : "",
              travel_mode: expense.travel_mode
                ? expense.travel_mode.code.toUpperCase()
                : "",
              from: expense.from ? expense.from.toUpperCase() : "",
              to: expense.to ? expense.to.toUpperCase() : "",
              description: expense.description
                ? expense.description.toUpperCase()
                : "",
              amount: expense.amount ? expense.amount.toUpperCase() : "",
              status: expense.is_approved == 1 ? "YES" : "NO",
              supervisor: expense.supervisor_id
                ? expense.supervisor.name.toUpperCase()
                : "",
              bill: expense.image_path
                ? `${this.mediaUrl}store-vyu/${expense.image_path}`
                : "",
            };
            this.ExportExpences.push(excel_expences);
            if (expense.amount) total += parseFloat(expense.amount);
            if (this.companyId == 9) {
          total += 100;
        }
          
          });
          this.ExportExpences.push({
            sr_no: "",
            name: "",
            employee_code: "",
            date: "",
            location: "",
            allowance_type: "",
            is_visited: "",
            expense_type: "",
            travel_mode: "",
            from: "",
            to: "",
            description: "Total",
            amount: total,
            status: "",
            supervisor: "",
            bill: "",
          });
          this.isExcelLoading = false;
        })
        .catch(() => {
          this.isExcelLoading = false;
        });
      //   this.All_expenses = All_expenses.data.data;
    },
    getMonthYear() {
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
    },
    async UpdateApprovalStatus(type) {
      if (type == "APPROVE") {
        this.isApproving = true;
      } else {
        this.isDenying = true;
      }
      let filtered_expense = [];
      this.expenses.forEach((expense) => {
        if (expense.is_selected == 1) {
          expense.is_approved = type == "APPROVE" ? 1 : 0;
          filtered_expense.push(expense);
        }
      });
      let payload = {
        datas: filtered_expense,
      };
      await axios
        .post(`/expenses/update_approval_status`, payload)
        .then(() => {});
      this.isApproving = false;
      this.isDenying = false;
    },
    selectAll() {
      console.log(this.is_select_all);
      this.expenses.forEach((expense) => {
        expense.is_selected = this.is_select_all;
      });
    },
  },
};
</script>
