<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add a User
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="User Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.employee_code"
                outlined
                label="Employee Code *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.employee_code"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.phone"
                outlined
                label="Phone *"
                prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="role"
                v-model="form.role_id"
                :items="roles"
                label="Roles *"
                @input="getData"
                prepend-icon="mdi-account"
                :error-messages="errors.role_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4" v-if="form.role_id != '9' && form.role_id != '2'">
              <v-autocomplete
                ref="supervisor"
                v-model="form.supervisor_id"
                :items="supervisors"
                label="Supervisor"
                @input="getData"
                prepend-icon="mdi-account"
                :error-messages="errors.supervisor_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="designation"
                v-model="form.designation_id"
                :items="designations"
                label="Designation *"
                @input="getData"
                prepend-icon="mdi-account"
                :error-messages="errors.designation_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-autocomplete
                ref="bloodgroup"
                v-model="form.blood_group_id"
                :items="bloodgroups"
                label="Blood Group *"
                @input="getData"
                prepend-icon="mdi-account"
                :error-messages="errors.blood_group_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.branch"
                outlined
                label="Branch"
                prepend-inner-icon="mdi-bank"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '9' || companyId == '1'">
              <v-text-field
                v-model="form.region_of_bsm"
                outlined
                label="Region of BSM"
                prepend-inner-icon="mdi-chemical-weapon"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '9' || companyId == '1'">
              <v-text-field
                v-model="form.asm_area"
                outlined
                label="ASM Area"
                prepend-inner-icon="mdi-map-marker-radius"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '9' || companyId == '1'">
              <v-text-field
                v-model="form.so_territory"
                outlined
                label="SO Territory"
                prepend-inner-icon="mdi-golf"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '9' || companyId == '1'">
              <v-text-field
                v-model="form.tsi_name"
                outlined
                label="TSI Name"
                prepend-inner-icon="mdi-account-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '9' || companyId == '1'">
              <v-text-field
                v-model="form.uid_no"
                outlined
                label="UID No"
                prepend-inner-icon="mdi-buffet"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.pf_no"
                outlined
                label="PF No"
                prepend-inner-icon="mdi-buffet"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.esi_no"
                outlined
                label="Esi No"
                prepend-inner-icon="mdi-buffet"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.imei_no"
                outlined
                label="IMEI No"
                prepend-inner-icon="mdi-cellphone-settings"
              ></v-text-field>
            </v-col>

            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.uan_no"
                outlined
                label="UAN No"
                prepend-inner-icon="mdi-buffet"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.emergency_2_name"
                outlined
                label="Emergency Contact Name"
                prepend-inner-icon="mdi-account"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.emergency_2_contact"
                outlined
                label="Emergency Contact"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.emergency_2_relationship"
                outlined
                label="Emergency Contact Reletion"
                prepend-inner-icon="mdi-account-multiple"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.emergency_2_name_2"
                outlined
                label="Emergency Contact Name 2"
                prepend-inner-icon="mdi-account"
              ></v-text-field>
            </v-col>

            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.emergency_2_contact_2"
                outlined
                label="Emergency Contact 2"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.emergency_2_relationship_2"
                outlined
                label="Emergency Contact Relation 2"
                prepend-inner-icon="mdi-account-multiple"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '9' || companyId == '1'">
              <v-text-field
                v-model="form.payroll_company_name"
                outlined
                label="Payroll Company Name"
                prepend-inner-icon="mdi-office-building"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '9' || companyId == '1'">
              <v-text-field
                v-model="form.town"
                outlined
                label="Town"
                prepend-inner-icon="mdi-city"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '9' || companyId == '1'">
              <v-text-field
                v-model="form.state"
                outlined
                label="State"
                prepend-inner-icon="mdi-city-variant-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.pl_leave_count"
                outlined
                label="PL"
                prepend-inner-icon="mdi-city-variant-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-text-field
                v-model="form.cl_leave_count"
                outlined
                label="CL"
                prepend-inner-icon="mdi-city-variant-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'">
              <v-menu
                v-model="doj_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.doj"
                    label="Date Of Joining"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="doj = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :id="`doj`"
                  v-model="form.doj"
                  @change="doj_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4" v-if="companyId == '9' || companyId == '1'">
              <v-text-field
                v-model="form.district"
                outlined
                label="District"
                prepend-inner-icon="mdi-city-variant"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                v-model="form.active"
                inset
                :label="`Is Active: ${
                  form.active.toString() == 'true' ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
            <v-col sm="4" v-if="companyId == '10' || companyId == '1'" >
             <v-text-field
               v-model="form.geolocation_lat"
               outlined
               label="LAT"
               prepend-inner-icon="mdi-office-building"
             ></v-text-field>
           </v-col>
           <v-col sm="4" v-if="companyId == '10' || companyId == '1'" >
             <v-text-field
               v-model="form.geolocation_lng"
               outlined
               label="LNG"
               prepend-inner-icon="mdi-office-building"
             ></v-text-field>
           </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save"> Save User </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {
        active: true,
        doj: "",
      },
      companyId: "",
      doj_menu: false,
      roles: [
        {
          id: 0,
          text: "Select Role",
          value: "",
        },
      ],
      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      designations: [
        {
          id: 0,
          text: "Select Designation",
          value: "",
        },
      ],
      bloodgroups: [{ id: 0, text: "Select Blood Group", value: "" }],
      isLoading: false,
    };
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.form.supervisor_id = this.user.id;
    }
    this.getMasters();
    this.companyId = this.user.companies[0].id;
    console.log(this.companyId);
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`users/masters`);
      masters = masters.data;
      // Roles
      masters.roles.forEach((role) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (role.name == "USER") {
            this.roles.push({
              id: role.id,
              text: role.name,
              value: role.id,
            });
          }
        } else {
          this.roles.push({
            id: role.id,
            text: role.name,
            value: role.id,
          });
        }
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (supervisor.id == this.user.id) {
            this.supervisors.push({
              id: supervisor.id,
              text: supervisor.name,
              value: supervisor.id,
            });
          }
        } else {
          this.supervisors.push({
            id: supervisor.id,
            text: supervisor.name,
            value: supervisor.id,
          });
        }
      });
      //Blood Group
      masters.bloodgroups.forEach((bloodgroup) => {
        this.bloodgroups.push({
          id: bloodgroup.id,
          text: bloodgroup.description,
          value: bloodgroup.id,
        });
      });
      //Designation
      masters.designations.forEach((designation) => {
        this.designations.push({
          id: designation.id,
          text: designation.description,
          value: designation.id,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/users", this.form);
        this.isLoading = false;
        this.$router.push("/users");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
  },
};
</script>
