<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item class="my-4 mx-2 px-16">
        <router-link to="/">
          <v-img
            alt="StoreVYU"
            contain
            :src="require('../../assets/logo.png')"
            width="100"
          />
        </router-link>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav dense>
        <!-- Companies -->
        <v-list-item
          v-if="companyId == 1 && user.roles[0].name != 'SUPERVISOR'"
          :to="`/companies`"
          class="v-list-item"
        >
          <v-list-item-icon>
            <v-icon size="25"> mdi-factory </v-icon>
          </v-list-item-icon>

          <v-list-item-title class="text-subtitle-1"
            >Companies</v-list-item-title
          >
        </v-list-item>
        <!-- Users -->

        <v-list-item :to="`/users`" class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-account-group </v-icon>
          </v-list-item-icon>

          <v-list-item-title class="text-subtitle-1">Users</v-list-item-title>
        </v-list-item>
        <!-- User Attendances -->
        <v-list-group no-action :value="false">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25"> mdi-calendar-account </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1"
              >Attendance</v-list-item-title
            >
          </template>

          <v-list-item :to="`/user-attendances`">
            <v-list-item-icon>
              <v-icon size="25"> mdi-calendar-account </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Daily Attendance</v-list-item-title>
          </v-list-item>
          <v-list-item
            :to="`/user-regularizations`"
            v-if="
              (companyId == 9 || companyId == 1) &&
              user.roles[0].name != 'SUPERVISOR'
            "
          >
            <v-list-item-icon>
              <v-icon size="25"> mdi-calendar-edit-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Regularization</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/user-leaves`">
            <v-list-item-icon>
              <v-icon size="25"> mdi-calendar-minus-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Leaves</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- PJPs -->
        <v-list-item
          v-if="companyId == 9 || companyId == 1"
          :to="`/pjps`"
          class="v-list-item"
        >
          <v-list-item-icon>
            <v-icon size="25"> mdi-map-legend </v-icon>
          </v-list-item-icon>

          <v-list-item-title class="text-subtitle-1">PJPs</v-list-item-title>
        </v-list-item>
        <!-- Expenses -->
        <v-list-item
          v-if="companyId == 10 || companyId == 1"
          :to="`/pjps`"
          class="v-list-item"
        >
          <v-list-item-icon>
            <v-icon size="25"> mdi-map-legend </v-icon>
          </v-list-item-icon>

          <v-list-item-title class="text-subtitle-1"
            >Expenses</v-list-item-title
          >
        </v-list-item>
        <!-- Reports -->
        <v-list-group no-action :value="false">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25"> mdi-file-chart-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1"
              >Reports</v-list-item-title
            >
          </template>

          <v-list-item
            v-if="companyId == 9 || companyId == 1"
            :to="`/reports/monthly-attendances`"
          >
            <v-list-item-icon>
              <v-icon size="25"> mdi-calendar-account </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Monthly Attendances</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="companyId == 10 || companyId == 1"
            :to="`/reports/monthly_attendances`"
          >
            <v-list-item-icon>
              <v-icon size="25"> mdi-calendar-account </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Monthly Attendances</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/reports/expenses`">
            <v-list-item-icon>
              <v-icon size="25"> mdi-cash-register </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Expense</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item :to="`/reports/date-wise-expenses`">
            <v-list-item-icon>
              <v-icon size="25"> mdi-cash-register </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Date Wise Expense</v-list-item-title>
          </v-list-item> -->
          <!-- <v-list-item :to="`/reports/pjps`">
            <v-list-item-icon>
              <v-icon size="25"> mdi-map-marker-path </v-icon>
            </v-list-item-icon>
            <v-list-item-title>PJP</v-list-item-title>
          </v-list-item> -->
        </v-list-group>
        <!-- Settings -->
        <v-list-group no-action :value="false">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-cogs</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1"
              >Settings</v-list-item-title
            >
          </template>

          <v-list-item :to="`/values`">
            <v-list-item-icon>
              <v-icon size="25"> mdi-shape </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Values</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/value-lists`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Value Lists</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn class="primary" block color="white" text @click="logout">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-btn color="black" small text class="mt-7">
        {{ user.first_name != null ? user.first_name : user.email }}
        [ {{ companyName }} ] [ {{ roleName }}]
      </v-btn>
    </v-app-bar>

    <router-view />
  </div>
</template>

<script type="text/javascript">
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: true,
      companyName: "",
      companyId: "",
    };
  },
  mounted() {
    this.companyName = this.user.companies[0].name;
    console.log(this.companyname);
    this.companyId = this.user.companies[0].id;
    console.log(this.companyId);
  },
  components: {},
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
      location.replace("/");
    },
  },
};
</script>
<style scoped>
.nav-text {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.v-application--is-ltr
  .v-list--dense.v-list--nav
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding: 0 8px 0 20px;
}
</style>
