<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Users'"></span>
          <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            to="/users/create"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
          <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            to="/users/upload"
          >
            <v-icon dark> mdi-upload </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData"
                    @change="page = 1"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="designation"
                    v-model="form.designation_id"
                    :items="designations"
                    label="Designation"
                    @input="getData"
                    prepend-icon="mdi-account"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3">
                  <v-autocomplete
                    ref="role"
                    v-model="form.role_id"
                    :items="roles"
                    label="Roles"
                    @input="getData"
                    prepend-icon="mdi-account"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="supervisor"
                    v-model="form.supervisor_id"
                    :items="supervisors"
                    label="Supervisor"
                    @input="getData"
                    prepend-icon="mdi-account"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="status"
                    v-model="form.active"
                    :items="statuses"
                    label="Status"
                    @input="getData"
                    prepend-icon="mdi-toggle-switch"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="users.length != 0">
              <v-spacer></v-spacer>
              <v-btn class="primary mx-4" @click="generateExcel()">
                <span v-if="isExcelloading"> Generating Excel... </span>
                <span v-else>Generate Excel</span>
              </v-btn>
            </v-card-title>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Role</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Phone</th>
                    <th class="text-left">Supervisor</th>
                    <!-- <th class="text-left">Date Of Joining</th> -->
                    <th class="text-left">Designation</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody v-if="users.length != 0">
                  <tr v-for="(user, i) in users" :key="`user${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="user.active == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>User is Active</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="user.active == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="red"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>User is InActive</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <!-- <td>{{ user.roles && user.roles.length > 0 ? user.roles[0].name : "" }}</td> -->
                    <!-- <td>{{ user.roles[0].name }}</td> -->
                    <td>{{ user.roles[0] ? user.roles[0].name : "" }}</td>
                    <td>{{ user.name || "" }}</td>
                    <td>{{ user.email || "" }}</td>
                    <td>{{ user.phone || "" }}</td>
                    <!-- <td>
                      {{ user.supervisor_id ? user.supervisor?.name : "" }}
                    </td> -->
                    <td>
                      {{ user.supervisor_id ? user.supervisor?.name : "" }}
                    </td>
                    <td>
                      {{ user.designation ? user.designation.code : "" }}
                    </td>

                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/users/${user.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                      <!-- <v-btn
                        class="mx-2"
                        color="error"
                        fab
                        x-small
                        @click="Delete_this(user.id)"
                      >
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn> -->
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="12" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "usersPage",
  data() {
    return {
      form: {
        active: "",
        supervisor_id: "",
        role_id: "",
        designation_id: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      users: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      roles: [
        {
          id: 0,
          text: "Select Role",
          value: "",
        },
      ],
      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      designations: [
        {
          id: 0,
          text: "Select Designation",
          value: "",
        },
      ],
      isLoading: false,
      isExcelloading: false,
      ExportUsers: [],
      Export_users: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Email: "email",
        Phone: "phone",
        Supervisor: "supervisor",
        "Is Active": "status",
      },
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.form.supervisor_id = this.user.id;
    }
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`users/masters`);
      masters = masters.data;
      // Roles
      masters.roles.forEach((role) => {
        this.roles.push({
          id: role.id,
          text: role.name,
          value: role.id,
        });
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (supervisor.id == this.user.id) {
            this.supervisors.push({
              id: supervisor.id,
              text: supervisor.name,
              value: supervisor.id,
            });
          }
        } else {
          this.supervisors.push({
            id: supervisor.id,
            text: supervisor.name,
            value: supervisor.id,
          });
        }
      });
      //Designation
      masters.designations.forEach((designation) => {
        this.designations.push({
          id: designation.id,
          text: designation.description,
          value: designation.id,
        });
      });
    },
    async getData() {
      console.log(this.form.supervisor_id);
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.active = this.active ? this.active : "";
      this.role_id = this.role_id ? this.role_id : "";
      let users = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.form.active +
        "&roleId=" +
        this.form.role_id +
        "&supervisorId=" +
        this.form.supervisor_id +
        "&designationId=" +
        this.form.designation_id;
      console.log(query);
      users = await axios.get(`users?${query}`);
      this.users = users.data.data;
      // console.log(this.users);
      this.count = users.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
    //  async Delete_this(id) {
    //   let check = confirm("Are you sure you want to delete the data");
    //   if (check) {
    //     try {
    //       this.isLoading = true;
    //       await axios.delete(`users/${id}`);
    //       this.isLoading = false;
    //       this.getData();
    //     } catch (e) {
    //       this.isLoading = false;
    //     }
    //   }
    // },

    async addToExcelData() {
      this.isExcelLoading = true;
      this.title = "Company Lists";
      let Export_users = [];
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.form.active +
        "&roleId=" +
        this.form.role_id;
      Export_users = await axios.get(`users?${query}`);
      this.Export_users = Export_users.data.data;

      this.Export_users.forEach((user) => {
        this.ExportUsers.push({
          sr_no: user.sr_no,
          name: user.name ? user.name.toUpperCase() : "",
          email: user.email ? user.email.toUpperCase() : "",
          phone: user.phone ? user.phone : "",

          supervisor: user.supervisor_id
            ? user.supervisor.name.toUpperCase()
            : "",
          status: user.active == 1 ? "YES" : "NO",
        });
      });
      this.isExcelLoading = false;
    },
    async generateExcel() {
      this.isExcelloading = true;
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.form.active +
        "&roleId=" +
        this.form.role_id;

      try {
        let response = await axios.get(`usersExports?${query}`);
        if (response && response.data && response.data.data) {
          window.open(`${response.data.data}`, "_blank");
        } else {
          console.error("Invalid response data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching Excel data:", error);
      }
      this.isExcelloading = false;
    },
  },
};
</script>
