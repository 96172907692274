<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit PJP
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="employee"
                v-model="form.user_id"
                :items="employees"
                label="Employee"
                prepend-icon="mdi-account"
                :error-messages="errors.user_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.date = null"
                    :error-messages="errors.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date"
                  @change="date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu
                ref="Mark in time"
                v-model="login_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.markin_time"
                    label="Mark in time"
                    prepend-icon="mdi-clock-time-nine-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="login_time_menu"
                  v-model="form.markin_time"
                  full-width
                  @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu
                ref="Mark out time"
                v-model="logout_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.markout_time"
                    label="Mark out time"
                    prepend-icon="mdi-clock-time-nine-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="logout_time_menu"
                  v-model="form.markout_time"
                  full-width
                  @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.location"
                outlined
                :label="label"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.location"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.description"
                outlined
                label="Description"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4" v-if="companyId == '9' || companyId == '1'">
              <v-autocomplete
                ref="allowance"
                v-model="form.allowance_type_id"
                :items="allowanceTypes"
                label="Allowance Type"
                prepend-icon="mdi-call-split"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-switch
                v-model="form.is_visited"
                inset
                :label="`Is Active: ${form.is_visited == 1 ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
            <v-col sm="4" v-if="form.is_visited == 0">
              <v-text-field
                v-model="form.reason_for_not_visit"
                outlined
                label="Reason for not visit"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>

          <v-btn v-else color="primary" dark @click="save"> Update PJP </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserEditPage",
  data() {
    return {
      form: {
        is_visited: false,
        markin_time: "",
        markout_time: "",
      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      allowanceTypes: [
        {
          id: 0,
          text: "Select Allowance Type",
          value: "",
        },
      ],
      isLoading: false,
      date_menu: false,
      login_time_menu: false,
      logout_time_menu: false,
    };
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.form.supervisor_id = this.user.id;
    }
    this.getData();
    this.getMasters();
    this.companyId = this.user.companies[0].id;
    console.log(this.companyId);
  },
  computed: {
    label() {
      if (this.companyId == 9 || this.companyId == 1) {
        return "Outlet Name";
      } else if (this.companyId == 10 || this.companyId == 1) {
        return "Location";
      } else {
        return ""; // Return an empty string or a default label for other cases
      }
    },
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`pjps/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (employee.supervisor_id == this.user.id) {
            this.employees.push({
              id: employee.id,
              text: employee.name,
              value: employee.id,
            });
          }
        } else {
          this.employees.push({
            id: employee.id,
            text: employee.name,
            value: employee.id,
          });
        }
      });
      // Session Types
      masters.allowanceTypes.forEach((sessionType) => {
        this.allowanceTypes.push({
          id: sessionType.id,
          text: sessionType.code,
          value: sessionType.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/pjps/${this.$route.params.pjpId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        await axios.patch(`/pjps/${this.$route.params.pjpId}`, this.form);
        this.isLoading = false;
        this.$router.push("/pjps");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
