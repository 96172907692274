<template>
  <v-container fill-height>
    <v-row align="center" justify="center" style="height: 90vh" dense>
      <v-col class="py-12">
        <v-card elevation="2" class="mx-auto" variant="outlined">
          <v-row class="grey lighten-3" style="height: 600px">
            <v-col md="6" sm="6" lg="6" align-self="center">
              <v-img
                class="mx-auto"
                :src="require('../../assets/logo.png')"
                width="200"
              ></v-img>
            </v-col>
            <v-col
              md="6"
              sm="6"
              lg="6"
              class="px-12 grey lighten-4"
              style="height: 600px"
            >
              <v-col class="mt-16 pt-16">
                <v-card-title class="text-h4 mb-1 font-weight-bold"
                  >Welcome back</v-card-title
                >
                <v-card-subtitle class="font-weight-bold"
                  >Welcome back, Please enter your details.</v-card-subtitle
                >
                <v-card-text>
                  <v-text-field
                    v-model="form.email"
                    outlined
                    label="Email"
                    prepend-inner-icon="mdi-email"
                    :error-messages="errors.email"
                  ></v-text-field>

                  <v-text-field
                    v-model="form.password"
                    type="password"
                    outlined
                    label="Password"
                    prepend-inner-icon="mdi-lock"
                    :error-messages="errors.password"
                  ></v-text-field>
                  <v-progress-circular
                    v-if="isLoading"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-btn v-else color="primary" dark @click="login">
                    Login
                  </v-btn>
                </v-card-text>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loggedInRole: "",
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      logIn: "auth/logIn",
    }),
    async login() {
      this.isLoading = true;
      this.logIn(this.form)
        .then(() => {
          location.reload("/");
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
      this.isLoading = false;
    },
  },
};
</script>
