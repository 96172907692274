<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit PJP Expense
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="employee"
                v-model="form.user_id"
                :items="employees"
                label="Employee"
                prepend-icon="mdi-account"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="Expense type"
                v-model="form.expense_type_id"
                :items="ExpenseTypes"
                label="Expense Type"
                prepend-icon="mdi-cash-100"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="travel mode"
                v-model="form.travel_mode_id"
                :items="travelModes"
                label="Travel Mode"
                prepend-icon="mdi-train-car"
              ></v-autocomplete>
            </v-col>

            <v-col sm="4">
              <v-text-field
                v-model="form.from"
                outlined
                label="From"
                prepend-inner-icon="mdi-arrow-left-bold"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.to"
                outlined
                label="To"
                prepend-inner-icon="mdi-arrow-right-bold"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.amount"
                outlined
                label="Amount"
                prepend-inner-icon="mdi-cash"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="allowance"
                v-model="form.supervisor_id"
                :items="supervisors"
                label="Supervisor"
                prepend-icon="mdi-account"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-switch
                v-model="form.is_approved"
                inset
                :label="`Is Approved: ${form.is_approved == 1 ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
            <v-col sm="4" v-if="form.is_approved == 0">
              <v-text-field
                v-model="form.decline_reason"
                outlined
                label="Decline reason"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>

          <v-btn v-else color="primary" dark @click="save">
            Update PJP Expense</v-btn
          >
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserEditPage",
  data() {
    return {
      form: {
        is_approved: false,
      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      ExpenseTypes: [
        {
          id: 0,
          text: "Select Expense Type",
          value: "",
        },
      ],
      travelModes: [
        {
          id: 0,
          text: "Select Travel Mode",
          value: "",
        },
      ],
      supervisors: [
        {
          id: 0,
          text: "Select Supervisor",
          value: "",
        },
      ],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`pjp_expenses/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.name,
          value: employee.id,
        });
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        this.supervisors.push({
          id: supervisor.id,
          text: supervisor.name,
          value: supervisor.id,
        });
      });
      // Trave Modes
      masters.travel_modes.forEach((travelMode) => {
        this.travelModes.push({
          id: travelMode.id,
          text: travelMode.code,
          value: travelMode.id,
        });
      });
      // Expense Types
      masters.expense_types.forEach((ExpenseType) => {
        this.ExpenseTypes.push({
          id: ExpenseType.id,
          text: ExpenseType.code,
          value: ExpenseType.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `/pjp_expenses/${this.$route.params.pjpExpenseId}`
      );
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.patch(
          `/pjp_expenses/${this.$route.params.pjpExpenseId}`,
          this.form
        );
        this.isLoading = false;
        this.$router.push(`/pjps/${this.$route.params.pjpId}/pjp-expenses`);
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
