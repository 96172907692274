<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-btn text>
          <a :href="`${path}store-vyu/samples/user_upload_v1_0.xlsx`"
            >Download sample file</a
          >
        </v-btn>
        <v-card-title>
          <!-- <backbutton></backbutton> -->
          Upload Users Data
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="crude_users.length == 0">
              <input
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="application/ms-excel"
                multiple
                @input="handleFileUpload"
              />
            </v-col>
            <v-col v-else>
              <v-btn
                color="success"
                :loading="isProcessing"
                :disabled="isProcessing"
                @click="processFile"
              >
                Upload file</v-btn
              >

              &nbsp;
              <v-btn color="error" @click="truncatePendingDocuments">
                Delete</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <span style="color: red" v-show="isError">{{ error_msg }} </span>
          </v-row>
        </v-card-text>
      </v-card>
      <br />
      <v-card elevation="6">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Employee Code</th>
                <th class="text-left">Email</th>
                <th class="text-left">Phone</th>
                <th class="text-left">Role</th>
                <th class="text-left">Supervisor</th>
                <th class="text-left">Designation</th>
                <th class="text-left">Branch</th>
                <th class="text-left">Asm Area</th>
                <th class="text-left">So Territory</th>
                <th class="text-left">Tsi Name</th>
                <th class="text-left">UID No</th>
                <th class="text-left">Payroll Company Name</th>
                <th class="text-left">Town</th>
                <th class="text-left">State</th>
                <th class="text-left">District</th>
                  <th class="text-left">Region Of Bsm</th>
                <th class="text-left">is Active</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cruduser, at) in crude_users" :key="`cruduser${at}`">
                <td>{{ cruduser.name }}</td>
                <td>{{ cruduser.employee_code}}</td>
                <td>{{ cruduser.email }}</td>
                <td>{{ cruduser.phone }}</td>
                <td>{{ cruduser.role }}</td>
                <td>{{ cruduser.supervisor }}</td>
                <td>{{ cruduser.designation}}</td>
                <td>{{ cruduser.branch }}</td>
                <td>{{ cruduser.asm_area }}</td>
                <td>{{ cruduser.so_territory }}</td>
                <td>{{ cruduser.tsi_name }}</td>
                <td>{{ cruduser.uid_no }}</td>
                <td>{{ cruduser.payroll_company_name }}</td>
                <td>{{ cruduser.town }}</td>
                <td>{{ cruduser.state }}</td>
                <td>{{ cruduser.district }}</td>
               <td>{{cruduser.region_of_bsm}}</td> 
                <td>{{ cruduser.active }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <!-- Upload Success Dialog Box -->
      <v-dialog v-model="is_Uploaded" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-cloud-upload-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>
                        The file has been uploaded successfully. Now you can
                        Preview the List & process accordingly.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Uploaded = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Process Success Dialog Box -->
      <v-dialog v-model="is_Processed" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-progress-download
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>
                        The file has been processed successfully. Now you can
                        see your users in User's Tab.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Processed = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Truncate Success Dialog Box -->
      <v-dialog v-model="is_Truncated" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: red; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: red">Deleted!</h1>
                      <br />
                      <h3>
                        The file has been deleted successfully. Now you can
                        select a new file to upload.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Truncated = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
// import backbutton from "@/components/backbutton.vue";
export default {
  //   components: { backbutton },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Upload User Excel",
  },
  data() {
    return {
      crude_users: [],
      isLoading: false,
      is_mail_sent: false,
      isUploading: false,
      is_Uploaded: false,
      isProcessing: false,
      is_Processed: false,
      is_Truncated: false,
      isError: false,
      error_msg: "",
      path: "",
    };
  },
  mounted() {
    this.getData();
    this.path = this.mediaUrl;
    console.log(this.path);
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crude_users = await axios.get(`/crude_users`);
      this.crude_users = crude_users.data.data;
      this.count = crude_users.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isUploading = true;
      this.isUploadingError = false;
      this.error_msg = "";
      this.users = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("users", this.users);
      this.changeValue = 100;
      await axios
        .post("upload_user", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crude_users = response.data.data;
          this.is_Uploaded = true;
        })
        .catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
    },
    async processFile() {
      try {
        this.isProcessing = true;
        await axios.get(`process_user`).catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while processing the File. Kindly check the file before processing ...";
        });
        await axios.get("truncate_users");
        this.crude_users = [];
        this.isProcessing = false;
        this.is_Processed = true;
        // alert("Processed Successfully");
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("truncate_users");
      this.is_Truncated = true;

      // alert("Truncated Successfully");
      this.crude_users = [];
    },
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
td {
  min-width: 130px !important;
}
</style>
