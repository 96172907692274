<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add Attendance
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="employee"
                v-model="form.user_id"
                :items="employees"
                label="Employee"
                prepend-icon="mdi-account"
                :error-messages="errors.user_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="supervisor"
                v-model="form.supervisor_id"
                :items="supervisors"
                label="Supervisor"
                @input="getData"
                prepend-icon="mdi-account"
                :error-messages="errors.supervisor_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.date = null"
                    :error-messages="errors.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date"
                  @change="date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu
                ref="logintime"
                v-model="login_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.login_time"
                    label="Login time"
                    prepend-icon="mdi-clock-time-nine-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.login_time = null"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="login_time_menu"
                  v-model="form.login_time"
                  full-width
                  @click:minute="$refs.logintime.save(form.login_time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu
                ref="logouttime"
                v-model="logout_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.logout_time"
                    label="Logout time"
                    prepend-icon="mdi-clock-time-nine-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="form.login_time ? false : true"
                    clearable
                    @click:clear="form.logout_time = null"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="logout_time_menu"
                  v-model="form.logout_time"
                  full-width
                  @click:minute="$refs.logouttime.save(form.logout_time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4" v-if="form.role_id != '4' && form.role_id != '2'">
              <v-autocomplete
                ref="session type"
                v-model="form.session_type_id"
                :items="sessionTypes"
                label="Session Type"
                prepend-icon="mdi-account"
                :error-messages="errors.session_type_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.remarks"
                outlined
                label="Remark"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save">
            Save User Attendance
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {
        login_time: "",
        logout_time: "",
        attendance_type: "Normal",
      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      sessionTypes: [
        {
          id: 0,
          text: "Select Session Type",
          value: "",
        },
      ],
      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      isLoading: false,
      date_menu: false,
      login_time_menu: false,
      logout_time_menu: false,
    };
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.form.supervisor_id = this.user.id;
    }
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (employee.supervisor_id == this.user.id) {
            this.employees.push({
              id: employee.id,
              text: employee.name,
              value: employee.id,
            });
          }
        } else {
          this.employees.push({
            id: employee.id,
            text: employee.name,
            value: employee.id,
          });
        }
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (supervisor.id == this.user.id) {
            this.supervisors.push({
              id: supervisor.id,
              text: supervisor.name,
              value: supervisor.id,
            });
          }
        } else {
          this.supervisors.push({
            id: supervisor.id,
            text: supervisor.name,
            value: supervisor.id,
          });
        }
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        this.supervisors.push({
          id: supervisor.id,
          text: supervisor.name,
          value: supervisor.id,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/user_attendances", this.form);
        this.isLoading = false;
        this.$router.push("/user-attendances");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
  },
};
</script>
