<template>
  <v-app id="inspire">
    <router-view />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isTabClosed: false,

      logoutTimer: null, // Timer for automatic logout
      inactivityDuration: 10 * 60 * 1000, // 10 minutes in milliseconds
    };
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  created() {
    this.startLogoutTimer();
    window.addEventListener("mousemove", this.resetLogoutTimer);
    window.addEventListener("keydown", this.resetLogoutTimer);
  },
  watch: {
    isTabClosed(newValue) {
      if (newValue == true && performance.navigation.type != 0) {
        this.logout();
        console.log("User left the page or closed the tab");
      }
    },
  },
  methods: {
    startLogoutTimer() {
      this.logoutTimer = setTimeout(() => {
        this.logout();
      }, this.inactivityDuration);
    },
    resetLogoutTimer() {
      clearTimeout(this.logoutTimer);
      this.startLogoutTimer();
    },
    handleBeforeUnload() {
      this.isTabClosed = true;
      // event.preventDefault();
      // event.returnValue = "";
    },

    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
      location.replace("/");
    },
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.resetLogoutTimer);
    window.removeEventListener("keydown", this.resetLogoutTimer);
    clearTimeout(this.logoutTimer);
  },
};
</script>
