<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-if="companyId == 9"
            v-text="'PJPs'"
          ></span>
          <span
            class="text-h5 font-weight-bold"
            v-if="companyId == 10 || companyId == 1"
            v-text="'Expenses'"
          ></span>

          <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            to="/pjps/create"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
          <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            to="/pjps/upload"
          >
            <v-icon dark> mdi-upload </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData"
                    @change="page = 1"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="employee"
                    v-model="user_id"
                    :items="employees"
                    label="Employee"
                    @input="getData"
                    prepend-icon="mdi-account"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="2">
                  <v-autocomplete
                    ref="supervisor"
                    v-model="supervisor_id"
                    :items="supervisors"
                    label="Supervisor"
                    @input="getData"
                    prepend-icon="mdi-account"
                  ></v-autocomplete>
                </v-col>
                <v-col md="2">
                  <v-autocomplete
                    ref="visit"
                    v-model="isVisited"
                    :items="statuses"
                    label="Visit Status"
                    @input="getData"
                    prepend-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="2">
                  <v-menu
                    v-model="date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_filter"
                        label="Date Search"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @input="getData"
                        @click:clear="date_filter = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_filter"
                      @input="getData"
                      @change="date_menu = false"
                    ></v-date-picker> </v-menu
                ></v-col>
                <v-col sm="3">
                  <v-menu
                    v-model="month_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.month_year"
                        label="Year - Month"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="(filter.month_year = null), getData()"
                        :error-messages="errors.month"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.month_year"
                      type="month"
                      @change="(month_menu = false), getData()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="pjps.length != 0"
              ><v-spacer></v-spacer
              ><v-btn
                class="primary mx-4"
                v-if="ExportPjps.length == 0"
                @click="generateExcel()"
                ><span v-if="isExcelloading"> Generating Excel... </span>
                <span v-else>Generate Excel</span></v-btn
              >
              <v-btn
                target="_blank"
                :href="`${mediaUrl}store-vyu/exports/PjpsExport.xlsx`"
                v-else
                class="success mx-4"
              >
              </v-btn
            ></v-card-title>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Employee Name</th>
                    <th class="text-left">Employee Code</th>
                    <th class="text-left">Date</th>
                    <th
                      class="text-left"
                      v-if="companyId == 9 || companyId == 1"
                    >
                      Store Name
                    </th>
                    <th class="text-left" v-else-if="companyId == 10">
                      Location
                    </th>
                    <!-- <th class="text-left" v-if="companyId == 9 || companyId == 1">Store Name</th>
                    <th class="text-left" v-if="companyId == 11 || companyId == 1">Location</th> -->
                    <th class="text-left">Mark In Time</th>
                    <th class="text-left">Mark In Address</th>
                    <th class="text-left">Mark Out Time</th>
                    <th class="text-left">Mark Out Address</th>
                    <th class="text-left" v-if="companyId == 9">
                      Allowance Type
                    </th>
                    <th class="text-left">Visited</th>
                    <th class="text-left">Reason for not visit</th>
                    <th class="text-left">Supervisor</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody v-if="pjps.length != 0">
                  <tr v-for="(pjp, i) in pjps" :key="`pjp${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="pjp.is_visited == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Visited</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="pjp.is_visited == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="red"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Not Visited</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <!-- <td>{{ pjp.user.name || "" }}</td> -->
                    <td>{{ pjp.user ? pjp.user.name : "" }}</td>
                    <!-- <td>{{ pjp.user.employee_code || "" }}</td> -->
                    <td>{{ pjp.user ? pjp.user.employee_code : "" }}</td>
                    <td>{{ pjp.date || "" }}</td>
                    <td>{{ pjp.location || "" }}</td>
                    <td>{{ pjp.markin_time | hhmm }}</td>
                    <td v-if="pjp.markin_time">
                      {{ pjp.markin_address || "" }}
                    </td>
                    <td v-else></td>
                    <td>{{ pjp.markout_time | hhmm }}</td>
                    <td v-if="pjp.markout_time">
                      {{ pjp.markout_address || "" }}
                    </td>
                    <td v-else></td>
                    <td v-if="companyId == 9">
                      {{ pjp.allowance_type?.code || "" }}
                    </td>
                    <td>{{ pjp.is_visited ? "YES" : "NO" }}</td>
                    <td>{{ pjp.reason_for_not_visit || "" }}</td>
                    <!-- <td>{{ pjp.user.supervisor.name || "" }}</td> -->
                    <!-- <td>{{ pjp.user.supervisor ? pjp.user.supervisor.name : "" }}</td> -->
                    <!-- <td>{{ pjp.user.supervisor ? pjp.user.supervisor.name || "" : "" }}</td> -->
                    <td>
                      {{
                        pjp.user && pjp.user.supervisor
                          ? pjp.user.supervisor.name || ""
                          : ""
                      }}
                    </td>

                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/pjps/${pjp.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                      <!-- <v-btn
                        fab
                        x-small
                        class="my-auto"
                        color="error"
                        @click="Delete_this(pjp.id)"
                      >
                        <v-icon dark> mdi-delete </v-icon>
                       </v-btn>  -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2"
                            fab
                            x-small
                            color="primary"
                            :to="`/pjps/${pjp.id}/pjp-expenses`"
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </template>
                        <span>Add Pjp Expenses</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="11" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "pjpsPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
      },
      isVisited: "",
      supervisor_id: "",
      user_id: "",
      date_filter: "",
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      statuses: [
        {
          id: 0,
          text: "Select Visit Status",
          value: "",
        },
        { id: "VISITED", text: "VISITED", value: "1" },
        { id: "NOT VISITED", text: "NOT VISITED", value: "0" },
      ],

      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      allowanceTypes: [
        {
          id: 0,
          text: "Select Allowance Type",
          value: "",
        },
      ],
      companyId: "",
      date_menu: false,
      month_menu: false,
      isLoading: false,
      isExcelloading: false,
      ExportPjps: [],
      pjps: [],
      Export_pjps: [],
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.companyId = this.user.companies[0].id;
    console.log(this.companyId);
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.supervisor_id = this.user.id;
    }
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`pjps/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (employee.supervisor_id == this.user.id) {
            this.employees.push({
              id: employee.id,
              text: employee.name,
              value: employee.id,
            });
          }
        } else {
          this.employees.push({
            id: employee.id,
            text: employee.name,
            value: employee.id,
          });
        }
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (supervisor.id == this.user.id) {
            this.supervisors.push({
              id: supervisor.id,
              text: supervisor.name,
              value: supervisor.id,
            });
          }
        } else {
          this.supervisors.push({
            id: supervisor.id,
            text: supervisor.name,
            value: supervisor.id,
          });
        }
      });
      // Session Types
      masters.allowanceTypes.forEach((allowanceType) => {
        this.allowanceTypes.push({
          id: allowanceType.id,
          text: allowanceType.code,
          value: allowanceType.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.isVisited = this.isVisited ? this.isVisited : "";
      this.role_id = this.role_id ? this.role_id : "";
      let pjps = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&isVisited=" +
        this.isVisited +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&userId=" +
        this.user_id +
        "&date=" +
        this.date_filter +
        "&supervisorId=" +
        this.supervisor_id;
      pjps = await axios.get(`pjps?${query}`);
      this.pjps = pjps.data.data;
      console.log(this.pjps);
      this.count = pjps.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },

    async generateExcel() {
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&isVisited=" +
        this.isVisited +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&userId=" +
        this.user_id +
        "&date=" +
        this.date_filter +
        "&supervisorId=" +
        this.supervisor_id;
      let response = await axios.get(`pjpsExports?${query}`);
      console.log(response.data.data);
      if (response) {
        window.open(`${response.data.data}`, "_blank");
      }
    },
    getMonthYear() {
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
    },
     async Delete_this(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.delete(`pjps/${id}`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
